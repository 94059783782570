<template>
  <div class="card">
    <div class="card-header">
      <div class="text-center">
        <h4 class="card-title mb-0">{{ empresa }} - {{ data }} - {{ operador }}</h4>
      </div>
    </div>
    <div class="border">
      <ul class="nav nav-pills nav-customs nav-danger ms-3 mt-2" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-bs-toggle="tab" href="#cobranca" role="tab">
            Recebimentos
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-bs-toggle="tab" href="#pix" role="tab">
            Pix Recebidos
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content text-black">
      <div class="card-body tab-pane fade" role="tabpanel" id="pix">
        <recebimentos :header="header" :anotherHeader="pixHeader" tipo="pix" />
      </div>
      <div class="card-body tab-pane active" role="tabpanel" id="cobranca">
        <recebimentos :header="header" :anotherHeader="arquivoHeader" tipo="cobranca" />
      </div>
      <div class="ms-3 me-3">
        <chatBox />
      </div>
    </div>
  </div>
</template>

<script>
import chatBox from '@/components/chat/chat-box.vue'
import recebimentos from '@/components/detalhamento/recebimentos.vue'

const numberFormatCurrency = new Intl.NumberFormat(
  'pt-BR',
  {
    style: 'currency',
    currency: 'BRL'
  }
)

export default {
  data: () => ({
    formatCurrency: value => numberFormatCurrency.format(value),
    direito: null,
    tipo: 'pix',
    esquerdo: null,
    checkEsquerda: false,
    checkDireita: false,
    operador: 'Lucas Rangel',
    empresa: 'Rede São Roque',
    data: '27/03/2023',
    header: [
      { title: '#Id' },
      { title: 'Tipo' },
      { title: 'Cliente' },
      { title: 'Cupom' },
      { title: 'Frete' },
      { title: 'Valor' },
      { title: 'Ações' },
      { title: '' }
    ],
    pixHeader: [
      { title: '' },
      { title: '#Id' },
      { title: 'Conta' },
      { title: 'Data' },
      { title: 'NSU' },
      { title: 'Valor' },
      { title: 'Ações' }
    ],
    arquivoHeader: [
      { title: '' },
      { title: '#Id' },
      { title: 'Nome' },
      { title: 'Ações' }
    ]
  }),
  components: {
    chatBox,
    recebimentos
  }
}
</script>