<template>
  <div class="live-preview">
    <div class="row">
      <div v-if="novaTabela.length < 5" class="col-lg-7">
        <h4 class="card-title text-center text-black">
          Recebimentos Não Conciliados
        </h4>
        <div class="card border card-border-primary mt-2">
          <div class="card-body row justify-content-center">
            <table class="table table-card">
              <thead>
                <tr>
                  <th scope="col" v-for="item in header" :key="item" v-text="item.title" />
                </tr>
              </thead>
              <tbody v-if="tipo == 'pix'">
                <tr v-for="item in tabelaEsquerdaPix" :key="item">
                  <td v-text="'#' + item.id" />
                  <td v-text="item.tipo" />
                  <td v-text="item.cliente" />
                  <td v-text="item.cupom" />
                  <td v-text="item.frete" />
                  <td v-text="formatCurrency(item.valor)" />
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a type="button" class="link-success fs-15">
                        <i class="ri-chat-new-line" />
                      </a>
                    </div>
                  </td>
                  <td>
                    <input @click="disableEsquerdaPix(item)" class="form-check-input" type="checkbox" id="checkPixEsquerdo">
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="item in tabelaEsquerda" :key="item">
                  <td v-text="'#' + item.id" />
                  <td v-text="item.tipo" />
                  <td v-text="item.cliente" />
                  <td v-text="item.cupom" />
                  <td v-text="item.frete" />
                  <td v-text="formatCurrency(item.valor)" />
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a type="button" class="link-success fs-15">
                        <i class="ri-chat-new-line" />
                      </a>
                    </div>
                  </td>
                  <td>
                    <input @click="disableEsquerda(item)" class="form-check-input" type="checkbox" id="check-esquerdo">
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-if="novaTabela.length < 5" class="col-lg-5">
        <h4 class="card-title text-center text-black">
          Arquivos Não Conciliados
        </h4>
        <div class="card border card-border-primary mt-2">
          <div class="card-body row justify-content-center">
            <table class="table table-card">
              <thead>
                <tr>
                  <th v-for="item in anotherHeader" :key="item" v-text="item.title" />
                </tr>
              </thead>
              <tbody v-if="tipo == 'cobranca'">
                <tr v-for="item in tabelaDireita" :key="item.nome">
                  <td>
                    <input @click="disableDireita(item)" class="form-check-input" type="checkbox" id="check-direito">
                  </td>
                  <td v-text="'#' + item.id" />
                  <td v-text="item.nome" />
                  <td>
                    <div class="hstack gap-3 text-center">
                      <a type="button" class="link-info fs-15" data-bs-toggle="modal" data-bs-target="#showImage">
                        <i class="ri-camera-line" />
                      </a>
                      <a @click="remove(tabelaDireita, tabelaDireita.indexOf(item))" class="link-danger fs-15"
                        type="button">
                        <i class="ri-delete-bin-line" />
                      </a>
                    </div>
                  </td>
                  <div id="showImage" class="modal fade" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modal-lg">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title text-center">
                            Imagem
                          </h4>
                        </div>
                        <div class="modal-body">
                          <img v-if="tipo != 'application/pdf'" class="mw-100" :src="galeria"
                            data-holder-rendered="true" />
                          <iframe v-else frameBorder="0" height="500px" width="100%" scrolling="auto" :src="galeria" />
                        </div>
                        <div class="modal-footer">
                          <b-button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                            Sair
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="item in tabelaDireitaPix" :key="item.nome">
                  <td>
                    <input @click="disableDireitaPix(item)" class="form-check-input" type="checkbox" id="checkPixDireito">
                  </td>
                  <td v-text="'#' + item.id" />
                  <td v-text="item.conta" />
                  <td v-text="item.data" />
                  <td v-text="item.nsu" />
                  <td v-text="formatCurrency(item.valor)" />
                  <td>
                    <div class="text-center">
                      <a type="button" class="link-info fs-15">
                        <i class="ri-printer-line" />
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h4 class="card-title text-center text-black">
    Recebimentos Conciliados
  </h4>
  <div class="card border card-border-primary mt-2">
    <div class="card-body row justify-content-center">
      <table class="table table-card">
        <thead>
          <tr v-if="tipo == 'pix'">
            <th scope="col" v-for="item in pixConciliateHeader" :key="item" v-text="item.title" />
          </tr>
          <tr v-else>
            <th v-for="item in conciliateHeader" :key="item" v-text="item.title" />
          </tr>
        </thead>
        <tbody v-if="tipo == 'pix'">
          <tr v-for="item in novaTabela" :key="item.conta">
            <td v-text="item.conta" />
            <td v-text="item.tipo" />
            <td v-text="item.cliente" />
            <td v-text="item.cupom" />
            <td v-text="item.frete" />
            <td v-text="item.nsu" />
            <td v-text="item.data" />
            <td v-text="formatCurrency(item.valor)" />
            <td>
              <div class="hstack gap-3 text-center">
                <a class="link-danger fs-15" type="button">
                  <i class="ri-refresh-line fs-17 align-middle" />
                </a>
                <a class="link-success fs-15" type="button">
                  <i class="ri-check-line fs-17 align-middle" />
                </a>
              </div>
            </td>
          </tr></tbody>
        <tbody v-else>
          <tr v-for="item in novaTabela" :key="item.nome">
            <td v-text="item.nome" />
            <td v-text="item.tipo" />
            <td v-text="item.cliente" />
            <td v-text="item.cupom" />
            <td v-text="item.frete" />
            <td v-text="formatCurrency(item.valor)" />
            <td>
              <div class="hstack gap-3 text-center">
                <a class="link-danger fs-15" type="button">
                  <i class="ri-refresh-line fs-17 align-middle" />
                </a>
                <a class="link-success fs-15" type="button">
                  <i class="ri-check-line fs-17 align-middle" />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
<script>
const numberFormatCurrency = new Intl.NumberFormat(
  'pt-BR',
  {
    style: 'currency',
    currency: 'BRL'
  }
)

export default {
  props: {
    header: {
      required: true,
      type: Object,
      default: () => {}
    },
    anotherHeader: {
      required: true,
      type: Object,
      default: () => {}
    },
    tipo: {
      required: false,
      type: String,
      default: () => {}
    }
  },
  data: () => ({
    formatCurrency: value => numberFormatCurrency.format(value),
    direito: null,
    esquerdo: null,
    checkEsquerda: false,
    checkDireita: false,
    tabelaEsquerda: [
      { id: '1', tipo: 'Cartão', cliente: 'James Wan', cupom: 666, frete: 123, valor: 23, checked: false },
      { id: '2', tipo: 'Cartão Crédito', cliente: 'James Gunn', cupom: 789, frete: 777, valor: 5, checked: false },
      { id: '3', tipo: 'Boleto', cliente: 'Zack Snyder', cupom: 545, frete: 564, valor: 13, checked: false },
      { id: '4', tipo: 'Cheque', cliente: 'Christopher Nolan', cupom: 184, frete: 102, valor: 230, checked: false },
      { id: '5', tipo: 'Cartão', cliente: 'Quentin Tarantino', cupom: 748, frete: 707, valor: 203, checked: false }
    ],
    tabelaDireita: [
      { id: '6', nome: 'Goodcard', checked: false },
      { id: '7', nome: 'Cartão com erro', checked: false },
      { id: '8', nome: 'Cheque', checked: false },
      { id: '9', nome: 'Cheque lançado errado', checked: false },
      { id: '10', nome: 'Depósito em conta', checked: false }
    ],
    tabelaEsquerdaPix: [
      { id: '1', tipo: 'Cartão', cliente: 'James Wan', cupom: 666, frete: 123, valor: 23, checked: false },
      { id: '2', tipo: 'Cartão Crédito', cliente: 'James Gunn', cupom: 789, frete: 777, valor: 5, checked: false },
      { id: '3', tipo: 'Boleto', cliente: 'Zack Snyder', cupom: 545, frete: 564, valor: 13, checked: false },
      { id: '4', tipo: 'Cheque', cliente: 'Christopher Nolan', cupom: 184, frete: 102, valor: 230, checked: false },
      { id: '5', tipo: 'Cartão', cliente: 'Quentin Tarantino', cupom: 748, frete: 707, valor: 203, checked: false }
    ],
    tabelaDireitaPix: [
      { id: '6', conta: 'ITAU - CORREA', data: '27/03', nsu: 'A78S4S', valor: 12, checked: false },
      { id: '7', conta: 'BB - BSB', data: '28/03', nsu: 'PW7YGB4', valor: 487, checked: false },
      { id: '8', conta: 'BRB - NUNEZ', data: '29/03', nsu: '7AQ28C', valor: 8, checked: false },
      { id: '9', conta: 'ITAU - BORGES', data: '30/03', nsu: '1SGH74', valor: 32, checked: false },
      { id: '10', conta: 'BB - LUZ', data: '31/03', nsu: 'SS96QG', valor: 781, checked: false }
    ],
    novaTabela: [],
    pixConciliateHeader: [
      { title: 'Conta' },
      { title: 'Tipo' },
      { title: 'Cliente' },
      { title: 'Cupom' },
      { title: 'Frete' },
      { title: 'Data' },
      { title: 'NSU' },
      { title: 'Valor' },
      { title: 'Ações' }
    ],
    conciliateHeader: [
      { title: 'Nome' },
      { title: 'Tipo' },
      { title: 'Cliente' },
      { title: 'Cupom' },
      { title: 'Frete' },
      { title: 'Valor' },
      { title: 'Ações' }
    ]
  }),
  computed: {
    isMarked() {
      return this.checkEsquerda == true && this.checkDireita == true
    }
  },
  methods: {
    conciliate() {
      const total = { ...this.esquerdo, ...this.direito }
      this.novaTabela.push(total)
      this.tabelaDireita.splice(this.tabelaDireita.indexOf(this.direito), 1)
      this.tabelaEsquerda.splice(this.tabelaEsquerda.indexOf(this.esquerdo), 1)
      this.direito = null
      this.esquerdo = null
      this.checkEsquerda = false
      this.checkDireita = false
    },
    conciliatePix() {
      const total = { ...this.esquerdo, ...this.direito }
      this.novaTabela.push(total)
      this.tabelaDireitaPix.splice(this.tabelaDireitaPix.indexOf(this.direito), 1)
      this.tabelaEsquerdaPix.splice(this.tabelaEsquerdaPix.indexOf(this.esquerdo), 1)
      this.direito = null
      this.esquerdo = null
      this.checkEsquerda = false
      this.checkDireita = false
    },
    remove(tabela, i) {
      tabela.splice(i, 1)
    },
    disableDireita(item) {
      item.checked = !item.checked
      this.checkDireita = !this.checkDireita
      if (this.checkDireita == true) {
        this.direito = item
      } else {
        this.direito = null
      }
      var inputs = document.querySelectorAll("[id=check-direito]")
      this.disable(inputs, this.checkDireita, this.tabelaDireita)
    },
    disableDireitaPix(item) {
      item.checked = !item.checked
      this.checkDireita = !this.checkDireita
      if (this.checkDireita == true) {
        this.direito = item
      } else {
        this.direito = null
      }
      var inputs = document.querySelectorAll("[id=checkPixDireito]")
      this.disable(inputs, this.checkDireita, this.tabelaDireitaPix)
    },
    disableEsquerda(item) {
      item.checked = !item.checked
      this.checkEsquerda = !this.checkEsquerda
      if (this.checkEsquerda == true) {
        this.esquerdo = item
      } else {
        this.esquerdo = null
      }
      var inputs = document.querySelectorAll("[id=check-esquerdo]")
      this.disable(inputs, this.checkEsquerda, this.tabelaEsquerda)
    },
    disableEsquerdaPix(item) {
      item.checked = !item.checked
      this.checkEsquerda = !this.checkEsquerda
      if (this.checkEsquerda == true) {
        this.esquerdo = item
      } else {
        this.esquerdo = null
      }
      var inputs = document.querySelectorAll("[id=checkPixEsquerdo]")
      this.disable(inputs, this.checkEsquerda, this.tabelaEsquerdaPix)
    },
    disable(inputs, disabled, tabela) {
      for (var i = 0; i < inputs.length; i++) {
        if (disabled == true) {
          if (tabela[i].checked != true) {
            inputs[i].disabled = true
          }
        } else {
          inputs[i].disabled = false
        }
      }
      if (this.isMarked) {
        if (this.tipo == 'pix') {
          this.conciliatePix()  
        } else {
          this.conciliate()
        }
        for (let i = 0; i < document.querySelectorAll("[type=checkbox]").length; i++) {
          document.querySelectorAll("[type=checkbox]")[i].disabled = false
        }
      }
    }
  }
}
</script>
  